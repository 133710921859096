import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
const en = {
	oneInstrumentAnalizerLink: "One Instrument Analizer",
	twoInstrumentsAnalizerLink: "Two Instruments Analizer",
	notifyForOneInstrumentLink: "Notify for one instrument",
	notifyForTwoInstrumentsLink: "Notify for two instruments",
	notifyForPriceLink: "Notify for price",
	oneInstrumentAnalizerSubTitle: "One Instrument Analizer",
	searchSecurityType: "Choose Security Type",
	searchByCompamyName: "Search by Compamy Name",
	symbol: "Symbol",
	notifyForTwoInstrumentsForm: "Notify for two instruments form",
	twoInstrumentsAnalizersubTitle: "Two Instrument Analizer",
	twoInstrumentsAnalizerFirstInstrument: "First instrument",
	twoInstrumentsAnalizerSecondInstrument: "Second instrument",
	companyName: "Company Name",
	firstCompanyName: "First Company Name",
	secondSompanyName: "Second Company Name",
	date: "Date",
	contractId: "Contract Id",
	title: "Are you sure ?",
	yes: "Yes",
	no: "No",
	pleaseWait: "Please wait",
	titlemodal: "We are checking your authentication",
	reload: "Reload this page",
	deviationPercentage: "Deviation Percentage",
	changePercentage: "Change Percentage",
	direction: "Direction",
	startPrice: "Start Price",
	startDatePrice: "Start Price Date",
	price: "Price",
	desiredPercentage: "Desired Percentage",
	undesiredPercentage: "Undesired Percentage",
	ratio: "Ratio",
	startDate: "Start Date",
	endDate: "End Date",
	chooseBar: "Choose Bar",
	actions: "Actions",
	desiredChangePrice: "Desired Change Price",
	desiredDeviationPrice: "Desired Deviation Price",
	actualChangePrice: "Actual Change Price",
	actualChangePercentage: "Actual Change Percentage",
	actualDeviationPrice: "Actual Deviation Price",
	actualDeviationPercentage: "Actual Deviation Percentage",
	changeDate: "Change Date",
	deviationDate: "Deviation Date",
	desiredPrice: "Desired Price",
	undesiredPrice: "Undesired Price",
	priceDate: "Price Date",
	computedRatio: "Computed Ratio",
	analize: "Analize",
	createNotify: "Create notify",
	back: "Back",
	notifyForPriceTitle: "Notifications for price",
	notifyForPriceItem: "Notification for price",
	notifyForPriceForm: "Notification for one price form",
	notifyForOneInstrumentTitle: "Notifications for one instrument",
	notifyForOneInstrumentItem: "Notification for one instrument",
	searchInstrument: "Search Instrument",
	notifyForOneInstrumentFormSubTitle: "Notify for one instrument form",
	notifyForTwoInstrumentsTitle: "Two Instrument Notification",
	market: "Market",
	conid: "Conid",
	choose: "Choose",
	startingPrice: "Starting Price",
	startingDateTime: "Starting Date Time",
	changedPrice: "Changed Price",
	changedDateTime: "Changed Date Time",
	сhangedPercent: "Changed Percent",
	deviationedPrice: "Deviationed Price",
	deviationedDateTime: "Deviationed Date Time",
	deviationedPercent: "Deviationed Percent",
	edit: "Edit",
	delete: "Delete",
	notNotIfy: "There is no notifiers yet",
	update: "Update",
	create: "Create",
	oneInstrumentNotifications: "One Instrument Notifications",
	priceChangeNotifier: "Price Change Notifier",
	priceChangeNotifications: "Price Change Notifications",
	notifyForTwoPriceLinkTwo: "Notify for two price",
};
const ru = {
	firstCompanyName: "Первое название компании",
	secondSompanyName: "Второе название компании",
	notifyForTwoPriceLinkTwo: "Уведомление об изменении двух цен",
	deviationDate: "Дата отклонения",
	notifyForTwoInstrumentsForm: "Уведомление для двух инструментов",
	oneInstrumentAnalizerLink: "Анализатор Одного Инструмента",
	twoInstrumentsAnalizerLink: "Анализатор Двух Инструментов",
	notifyForOneInstrumentLink: "Уведомление для одного инструмента",
	notifyForTwoInstrumentsLink: "Уведомление для двух инструментов",
	notifyForPriceLink: "Уведомление об изменении цены",
	oneInstrumentAnalizerSubTitle: "Анализатор Одного Инструмента",
	searchSecurityType: "Выберите тип ценной бумаги",
	searchByCompamyName: "Поиск по названию компании",
	symbol: "Символ",
	twoInstrumentsAnalizersubTitle: "Анализатор Двух Инструментов",
	twoInstrumentsAnalizerFirstInstrument: "Первый инструмент",
	twoInstrumentsAnalizerSecondInstrument: "Второй инструмент",
	companyName: "Название компании",
	date: "Дата",
	contractId: "Контракт Id",
	title: "Вы уверены ?",
	yes: "Да",
	no: "Нет",
	pleaseWait: "Please wait",
	titlemodal: "We are checking your authentication",
	reload: "Reload this page",
	deviationPercentage: "Процент отклонения",
	changePercentage: "Процент изменения",
	direction: "Направление",
	startPrice: "Стартовая цена",
	startDatePrice: "Start Price Date",
	price: "Цена",
	ratio: "Коэффициент",
	startDate: "Дата начала",
	endDate: "Дата окончания",
	chooseBar: "Выбрать период",
	actions: "Действия",
	desiredChangePrice: "Желаемая цена изменения",
	desiredDeviationPrice: "Желаемая цена отклонения",
	actualChangePrice: "Фактическая цена изменения",
	actualChangePercentage: "Фактический процент изменения",
	actualDeviationPrice: "Фактическая цена отклонения",
	actualDeviationPercentage: "Фактический процент отклонения",
	changeDate: "Дата изменения",

	desiredPercentage: "Желаемый процент",
	desiredPrice: "Желаемая цена",
	undesiredPercentage: "Нежелательный процент",
	undesiredPrice: "Нежелательная цена",
	priceDate: "Дата цены",
	computedRatio: "Соотношение",
	analize: "Анализировать",
	createNotify: "Создать уведомление",
	back: "Назад",
	notifyForPriceTitle: "Notifications for price",
	notifyForPriceItem: "Notification for price",
	notifyForPriceForm: "Notification for one price form",
	notifyForOneInstrumentTitle: "Уведомления для одного инструмента",
	notifyForOneInstrumentItem: "Уведомление для одного инструмента",
	searchInstrument: "Поиск Инструмента",
	notifyForOneInstrumentFormSubTitle: "Уведомление для одного инструмента",
	notifyForTwoInstrumentsTitle: "Уведомление для двух инструментов",
	market: "Рынок",
	conid: "Контракт Id",
	choose: "Выбрать",
	startingPrice: "Стартовая цена",
	startingDateTime: "Дата старта",
	changedPrice: "Измененая цена",
	changedDateTime: "Дата измененной цены",
	сhangedPercent: "Изменения процентов",
	deviationedPrice: "Отклоненная цена",
	deviationedDateTime: "Дата отклоненой цены",
	deviationedPercent: "Отклонение процентов",
	edit: "Редактировать",
	delete: "Удалить",
	notNotIfy: "Уведомленей пока нет",
	update: "Обновить",
	create: "Создать",
	oneInstrumentNotifications: "One Instrument Notifications",
	priceChangeNotifier: "Уведомление об изменении цены",
	priceChangeNotifications: "Уведомления об изменении цен",
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: "ru",
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		resources: {
			ru: {
				translation: ru,
			},
			en: {
				translation: en,
			},
		},
	});

export default i18n;
